.root {
}
.root a {
  text-decoration: none;
}

.root p {
  margin-bottom: 0px !important;
}

.error {
  text-align: center;
  padding: 2rem 0;
}

.centerMode {
}

.centerMode :global .slick-slide {
  opacity: 0.5;
  transition: 0.3s;
}

.centerMode :global .slick-slide:hover {
  opacity: 1;
}

.centerMode :global .slick-slide.slick-current {
  opacity: 1;
}

@media (max-width: 640px) {
  .galleryItems {
    grid-template-columns: repeat(2, 1fr);
  }

  .centerModeSmall {
  }

  .centerModeSmall :global .slick-slide {
    opacity: 0.5;
    transition: 0.3s;
  }

  .centerModeSmall :global .slick-slide:hover {
    opacity: 1;
  }

  .centerModeSmall :global .slick-slide.slick-current {
    opacity: 1;
  }
}

.carousel {
  composes: root;
  composes: root from '../Slider/slider.module.css';
}
