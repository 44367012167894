.root :global .pagebuilder-column-line {
  @apply !grid grid-cols-2 gap-5 pb-9 md:grid-cols-3 md:gap-9 md:pb-14 lg:mb-0.5 lg:!flex lg:grid-cols-5 lg:pb-16 xl:gap-5 2xl:mb-0 2xl:pb-16 3xl:-mb-1 3xl:pb-16;
}

.root :global .pagebuilder-column {
  @apply !w-auto !flex-auto md:whitespace-nowrap;
}

.root h4 {
  @apply mb-5 text-sm font-semibold text-heading md:text-base xl:text-lg 2xl:mb-6 3xl:mb-7;
}

.root ul {
  @apply mt-0 flex flex-col space-y-3 pl-0 pb-3 text-xs text-body md:pb-0 lg:space-y-3.5 lg:text-sm;
}

.root li {
  @apply items-baseline;
}

.root a {
  @apply text-inherit transition-colors duration-200 hover:text-black;
}

.root img {
  display: inline;
}
