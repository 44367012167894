.root {
    margin: 0;
}
.img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

@media (min-width: 48rem) {
    .mobileOnly {
        display: none;
    }
}
