.root {
  composes: border-2 from global;
  composes: border-solid from global;
  composes: cursor-pointer from global;
  composes: font-bold from global;
  composes: inline-flex from global;
  composes: items-center from global;
  composes: justify-center from global;
  composes: leading-tight from global;
  composes: max-w-full from global;
  composes: min-w-[10rem] from global;
  composes: outline-none from global;
  composes: pointer-events-auto from global;
  composes: px-sm from global;
  composes: rounded from global;
  composes: text-center from global;
  composes: text-sm from global;
  composes: uppercase from global;
  padding-top: calc(0.5rem + 1px); /* TODO @TW: review */
  padding-bottom: calc(0.5rem - 1px); /* TODO @TW: review */
  min-height: 2.5rem;
  transition-duration: 256ms;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--venia-global-anim-standard);

  composes: disabled_bg-gray-400 from global;
  composes: disabled_border-gray-400 from global;
  composes: disabled_opacity-50 from global;
  composes: disabled_pointer-events-none from global;
  composes: disabled_text-white from global;

  composes: focus_shadow-inputFocus from global;
}

.root:active {
  transition-duration: 128ms;
}

/**
 * Some browsers retain the :hover state after a click, this ensures if a button becomes disabled after
 * being clicked it will be visually disabled.
 */
.root:hover:disabled {
  /* TODO @TW: cannot compose. This may not be possible with two variants. */
  pointer-events: none;
}

.root_lowPriority {
  composes: root;

  composes: bg-transparent from global;
  composes: border-gray-700 from global;
  composes: text-gray-700 from global;

  composes: active_border-gray-800 from global;
  composes: active_text-gray-800 from global;

  composes: hover_border-gray-800 from global;
  composes: hover_text-gray-800 from global;

  @apply mt-1.5 inline-flex h-11 w-full cursor-pointer items-center justify-center rounded-md border-0 border-transparent bg-transparent px-2 py-4 text-center font-body text-[13px] font-semibold leading-4 text-heading underline placeholder-white transition duration-300 ease-in-out focus:outline-none focus-visible:outline-none md:h-12 md:px-3 md:py-3.5 md:text-sm lg:px-4 lg:py-4;
}

.root_normalPriority {
  composes: root;

  composes: bg-gray-300 from global;
  composes: border-brand-dark from global;
  composes: text-brand-dark from global;

  composes: active_border-brand-darkest from global;
  composes: active_text-brand-darkest from global;

  composes: hover_border-brand-darkest from global;
  composes: hover_text-brand-darkest from global;

  @apply mt-1.5 inline-flex h-11 w-full cursor-pointer items-center justify-center rounded-md border-0 border-transparent bg-gray-300 px-5 py-4 text-center font-body text-[13px] font-semibold leading-4 text-heading placeholder-white transition duration-300 ease-in-out hover:bg-gray-600 hover:text-white hover:shadow-cart focus:outline-none focus-visible:outline-none md:h-12 md:px-6 md:py-3.5 md:text-sm lg:px-8 lg:py-4;
}

.root_highPriority {
  composes: root;

  composes: bg-heading from global;
  composes: border-brand-dark from global;
  composes: text-white from global;

  composes: active_bg-brand-darkest from global;
  composes: active_border-brand-darkest from global;
  composes: active_text-white from global;

  composes: hover_bg-brand-darkest from global;
  composes: hover_border-brand-darkest from global;
  composes: hover_text-white from global;

  @apply mt-1.5 inline-flex h-11 w-full cursor-pointer items-center justify-center rounded-md border-0 border-transparent bg-heading px-5 py-4 text-center font-body text-[13px] font-semibold leading-4 text-white placeholder-white transition duration-300 ease-in-out hover:bg-gray-600 hover:text-white hover:shadow-cart focus:outline-none focus-visible:outline-none md:h-12 md:px-6 md:py-3.5 md:text-sm lg:px-8 lg:py-4;
}

.root_lowPriorityNegative,
.root_normalPriorityNegative {
  composes: root;

  composes: bg-transparent from global;
  composes: border-red-600 from global;
  composes: text-red-600 from global;

  composes: active_border-red-700 from global;
  composes: active_text-red-700 from global;

  composes: hover_border-red-700 from global;
  composes: hover_text-red-700 from global;
}

.root_highPriorityNegative {
  composes: root;

  composes: bg-red-600 from global;
  composes: border-red-600 from global;
  composes: text-white from global;

  composes: active_bg-red-700 from global;
  composes: active_border-red-700 from global;
  composes: active_text-white from global;

  composes: hover_bg-red-700 from global;
  composes: hover_border-red-700 from global;
  composes: hover_text-white from global;
}

.content {
  composes: gap-1.5 from global;
  composes: grid-flow-col from global;
  composes: inline-grid from global;
  composes: items-center from global;
  composes: justify-center from global;
  composes: justify-items-center from global;
}
