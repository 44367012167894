.cms-content {
  max-width: 1920px;
  margin: 0px auto;
}

.cms-content h1 {
  @apply mb-3.5 text-2xl text-heading md:text-3xl lg:text-4xl;
}

.cms-content h2 {
  @apply mb-3.5 text-xl text-heading md:text-2xl lg:text-3xl;
}

.cms-content h3 {
  @apply mb-3.5 text-lg text-heading md:text-xl lg:text-2xl;
}

.cms-content h4 {
  @apply mb-3.5 text-base font-bold text-heading md:text-lg lg:text-xl;
}

.cms-content p,
.cms-content p:last-of-type {
  @apply mb-3.5;
}

.cms-content ul {
  list-style: disc;
}

.cms-content ol {
  list-style: decimal;
}

.cms-content ul,
.cms-content ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.cms-content a {
  color: #006bb4;
}

.cms-content img {
  display: inline;
}

.max-width-md {
  @apply max-w-screen-md;
}

.max-width-lg {
  @apply max-w-screen-lg;
}

.max-width-xl {
  @apply max-w-screen-xl;
}

.max-width-2xl {
  @apply max-w-screen-2xl;
}
