.root {
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;
  --leftViewportElement: -99999px;
}
.root :global .slick-current {
  --leftViewportElement: 0;
}

.bannerRoot {
  composes: root from '../Banner/banner.module.css';
  min-height: inherit;
  height: 100%;
}
.bannerLink {
  composes: link from '../Banner/banner.module.css';
  min-height: inherit;
  height: 100%;
}
.bannerWrapper {
  composes: wrapper from '../Banner/banner.module.css';
  min-height: inherit;
  height: 100%;
}
.bannerPosterOverlay {
  composes: posterOverlay from '../Banner/banner.module.css';
  min-height: inherit;
  height: 100%;
}

.root :global .slick-slider {
  min-height: inherit;
}

.root:global(.home-slider) {
  @apply -mx-32 md:-mx-64 lg:-mx-[30rem] xl:-mx-[34rem];
}

.root:global(.home-slider .swiper-wrapper .swiper-slide) {
  width: 85%;
}

.root:global(.home-slider .swiper-navigation) button:first-child {
  @apply ml-[9rem] md:ml-[17rem] lg:ml-[31rem] xl:ml-[35rem];
}

.root:global(.home-slider .swiper-navigation) button:last-child {
  @apply mr-[9rem] md:mr-[17rem] lg:mr-[31rem] xl:mr-[35rem];
}

.root :global .slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  min-height: inherit;
}

.root :global .slick-list:focus {
  outline: none;
}

.root :global .slick-list.dragging {
  cursor: pointer;
}

.root :global .slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
  min-height: inherit;
}

.root :global .slick-track .slick-slide > div {
  min-height: inherit;
}

.root :global .slick-track:before,
.root :global .slick-track:after {
  content: '';
  display: table;
}

.root :global .slick-track:after {
  clear: both;
}

.root :global .slick-loading .slick-track {
  visibility: hidden;
}

.root :global .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: inherit;
}

[dir='rtl'] .root :global .slick-slide {
  float: right;
}

.root :global .slick-slide img {
  display: block;
}
.root :global .slick-slide.slick-loading img {
  display: none;
}

.root :global .slick-slide.dragging img {
  pointer-events: none;
}

.root :global .slick-initialized .slick-slide {
  display: block;
  visibility: hidden;
}

.root :global .slick-initialized .slick-slide.slick-active {
  visibility: visible;
}

.root :global .slick-loading .slick-slide {
  visibility: hidden;
}

.root :global .slick-vertical .slick-loading {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.root :global .slick-slider .slick-track,
.root :global .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.root :global .slick-arrow.slick-hidden {
  display: none;
}

.root :global .slick-prev {
  left: 1.6rem;
}
.root :global .slick-prev:before {
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 24 24' fill='none' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><line x1='19' y1='12' x2='5' y2='12' /><polyline points='12 19 5 12 12 5' /></svg>");
}

.root :global .slick-next {
  right: 1.6rem;
}
.root :global .slick-next:before {
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 24 24' fill='none' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><line x1='5' y1='12' x2='19' y2='12' /><polyline points='12 5 19 12 12 19' /></svg>");
}

.root :global .slick-prev,
.root :global .slick-next {
  background: rgba(252, 252, 252, 0.75);
  border: none;
  border-radius: 50px;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 40px;
  line-height: 0;
  opacity: 1;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
  z-index: 101;
}

.root :global .slick-prev:focus-visible,
.root :global .slick-next:focus-visible {
  outline: auto;
  outline: -webkit-focus-ring-color auto 1px;
}

.root :global .slick-dots {
  display: block;
  list-style: none;
  margin: 0;
  padding: 20px 0 18px 0;
  text-align: center;
  width: 100%;
}
.root :global .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin: 0 6px;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 15px;
}
.root :global .slick-dots li button {
  outline: none;
  background: rgb(var(--venia-global-color-gray-dark));
  filter: brightness(100%);
  border-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  display: block;
  height: 15px;
  padding: 0;
  text-indent: -99999px;
  transition: 0.5s;
  width: 15px;
  border: 1px solid #aaa;
}
.root :global .slick-dots li button:hover {
  filter: brightness(80%);
}
.root :global .slick-dots li button:focus {
  outline: 1px solid;
}

.root :global .slick-dots li button:active,
.root :global .slick-dots li button:focus,
.root :global .slick-dots li button:not(.primary) {
  box-shadow: none;
}

.root :global .slick-dots li.slick-active button {
  background: #1c1918;
}

.root :global .slick-current {
  z-index: 1;
}
