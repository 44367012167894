.root {
  --leftViewportElement: 0;
  --scrollBarWidth: var(--global-scrollbar-width, 0px);
}

.contained {
  composes: root;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
}

.fullBleed {
  composes: root;
  width: 100%;
}

/* .fullBleed :global .carouselWrapper {
  @apply -mx-32 sm:-mx-44 lg:-mx-60 xl:-mx-72 2xl:-mx-80;
} */

.root :global .swiper-navigation {
  @apply hidden md:block;
}

/* .fullBleed :global .swiper-navigation button:first-child {
  @apply mx-[9rem] sm:mx-[12rem] lg:mx-[16rem] xl:mx-[19rem] 2xl:mx-[21rem];
}

.fullBleed :global .swiper-navigation button:last-child {
  @apply mx-[9rem] sm:mx-[12rem] lg:mx-[16rem] xl:mx-[19rem] 2xl:mx-[21rem];
} */

.contained :global .swiper-navigation button:first-child {
  @apply mx-4;
}

.contained :global .swiper-navigation button:last-child {
  @apply mx-4;
}

.videoOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -99;
  -webkit-transform: translate3d(0, 0, 0);
}

.root :global [id*='jarallax-container'] video,
.root :global [id*='jarallax-container'] iframe {
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .root {
    background-attachment: scroll !important;
  }
}
