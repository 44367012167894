/* React Multi Carousel Custom css */
.heightFull,
.heightFull .carouselWrapper,
.heightFull .carouselWrapper > .swiper,
.heightFull .carouselWrapper > .swiper > .swiper-wrapper,
.heightFull .carouselWrapper > .swiper > .swiper-wrapper .swiper-slide > div,
.heightFull .carouselWrapper > .swiper > .swiper-wrapper .swiper-slide > a,
.heightFull .carouselWrapper > .swiper > .swiper-wrapper .swiper-slide > a > .group {
  height: 100%;
}
.carouselWrapper .swiper {
  height: 100%;
}
.swiper-wrapper .swiper-slide {
  padding-bottom: 2px;
  height: auto;
  width: auto;
}
.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  display: none;
}
.carouselWrapper .swiper-button-disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.carouselWrapper .swiper-button-disabled:hover {
  background-color: #fff !important;
  color: #000;
}
.carouselWrapper .swiper .swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  margin-top: 20px;
}
.carouselWrapper .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0px 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid theme('colors.icon');
  cursor: pointer;
}
.carouselWrapper .swiper .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: theme('colors.icon');
}

.carouselWrapper.dotsCircle .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  opacity: 0.5;
  transform: scale(0.7);
}
.carouselWrapper.dotsCircle
  .swiper
  .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
  transform: scale(1);
}
.carouselWrapper.pagination-left .swiper-pagination-bullets {
  display: inline-flex;
  text-align: left;
  width: auto;
}
.carouselWrapper.hero-grid-carousel .swiper-horizontal > .swiper-scrollbar {
  left: 0;
  width: 100%;
}
.carouselWrapper.hero-grid-carousel .swiper-horizontal > .swiper-wrapper {
  padding-bottom: 60px;
}
.testimonial-carousel .swiper {
  padding-bottom: 35px;
}
.testimonial-carousel .swiper-horizontal .swiper-scrollbar {
  background-color: #f1f1f1;
}
.testimonial-carousel .swiper-scrollbar-drag {
  background-color: #212121;
}

.ancient-testimonial .carousel-control {
  transition: 0.5s;
  visibility: visible;
  opacity: 0;
}
@media screen and (min-width: 768px) {
  .testimonial-carousel .swiper-slide-active > div {
    background-color: #fff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
  }
  .testimonial-carousel.reduce-child-padding .swiper-slide-active > div {
    background-color: #fff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
  }
  .testimonial-carousel.reduce-child-padding .swiper-slide-active > div {
    background-color: #fff;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
  }
  .testimonial-carousel .swiper .swiper-wrapper > div {
    padding: 15px 14px 0;
  }

  .testimonial-carousel.reduce-child-padding .swiper .swiper-wrapper > div {
    padding: 15px 5px 0;
  }

  .testimonial-carousel .swiper {
    padding-bottom: 45px;
    margin-left: -30px;
    margin-right: -14px;
    margin-top: -15px;
  }

  .testimonial-carousel .swiper .swiper-wrapper > div.swiper-slide-active {
    padding-left: 30px;
  }

  .testimonial-carousel.reduce-child-padding .swiper .swiper-wrapper > div.swiper-slide > div {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  }

  .testimonial-carousel .swiper-horizontal .swiper-scrollbar {
    width: calc(100% - 44px);
  }

  .testimonial-carousel .swiper-horizontal .swiper-scrollbar {
    width: calc(100% - 44px);
  }

  .ancient-testimonial:hover .carousel-control {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (min-width: 1280px) {
  .testimonial-carousel .swiper {
    padding-bottom: 60px;
  }
}
.swiper-navigation .swiper-button-lock {
  display: none;
}
