@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom base styles here */
@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  html,
  body {
    @apply font-body text-body antialiased;
  }
  p {
    @apply mb-5;
  }
  p:last-of-type {
    @apply mb-0;
  }
  p > a {
    @apply text-body transition;
  }
  p > a:hover {
    @apply text-heading;
  }
}

@layer components {
  .menuIcon {
    width: 26px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @apply space-y-1.5;
  }
  .bar {
    width: 100%;
    height: 2px;
    transition: all 0.25s ease-in-out;
    @apply flex rounded-2xl bg-heading;
  }
  .bar:first-child {
    width: 50%;
  }
  .bar:last-child {
    width: 75%;
  }
  .menuBtn:hover .bar:first-child,
  .menuBtn:hover .bar:last-child {
    @apply w-full;
  }
  .menuBtn:hover .bar:nth-child(2) {
    width: 60%;
  }

  /* Header Menu */
  #siteHeader.is-scrolling .innerSticky {
    @apply shadow-header;
  }

  /* Mobile Drawer Menu */
  .mobileMenu .menu-item.active {
    @apply font-semibold text-black;
  }
  .mobileMenu .menu-item.active::before {
    content: '';
    width: 3px;
    @apply absolute bottom-0 start-0 top-0 flex h-full bg-heading;
  }
  .mobileMenu li li {
    @apply text-sm text-body;
  }
  .mobileMenu li li div > a {
    @apply relative py-2.5 text-body;
  }
  .mobileMenu li > ul > li > div > a:before {
    content: '';
    height: 1px;
    @apply absolute start-1.5 top-1/2 flex w-1.5 bg-body md:start-3 md:w-2;
  }

  /* For login and signup switch */
  .switch {
    height: 22px;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #212121;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #707070;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #707070;
  }
  input::placeholder {
    color: #707070;
  }

  .popupClosed {
    @apply -end-3.5 -top-3.5 md:-end-4 md:-top-4;
  }
  .h-screen-40 {
    height: calc(100vh - 40px);
  }
  .modal-root .os-content {
    @apply flex items-center justify-center lg:block;
  }

  .cart-counter-badge {
    min-width: 20px;
    min-height: 20px;
    padding: 2px;
    border-radius: 20px;
    font-size: 10px;
  }

  @media (max-wdith: 1279px) {
    .cart-counter-badge {
      min-width: 16px;
      min-height: 16px;
    }
  }
  .carouselWrapper.testimonial-carousel .swiper-horizontal > .swiper-scrollbar {
    @apply md:start-7;
  }
  .carouselWrapper .product-gallery + div {
    @apply hidden;
  }
  .carouselWrapper.pagination-left .swiper-pagination-bullets {
    @apply start-6 sm:start-9 md:start-11 lg:start-14 xl:start-20 2xl:start-24 3xl:start-28;
  }
  .hero-slider-pagination-area .carouselWrapper.pagination-left .swiper-pagination-bullets {
    @apply start-6 sm:start-9 md:start-11 lg:start-14 xl:start-20 2xl:start-20;
  }
  .carouselWrapper.carousel-full-width.pagination-left .swiper-pagination-bullets {
    @apply start-5 sm:start-9 md:start-11 lg:start-14 xl:start-20 2xl:start-24 3xl:start-48;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    /* width: 0px;
    height: 0px;
    background: transparent; */
    width: 0;
    height: 0;
    display: none;
    -webkit-appearance: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scrollbar-color: transparent transparent;
  }

  .no-scrollbar::-webkit-scrollbar-thumb:horizontal,
  .no-scrollbar::-webkit-scrollbar-thumb:vertical {
    background: transparent;
    display: none;
  }

  .button[data-variant='main'] {
    background: linear-gradient(131deg, theme('colors.icon') 70%, transparent 72%) 100% 0/20px 100% no-repeat,
      linear-gradient(-49deg, theme('colors.icon') 70%, transparent 72%) 0 0/20px 100% no-repeat,
      linear-gradient(theme('colors.icon'), theme('colors.icon')) 18px 0 / calc(100% - 36px) 100% no-repeat;
  }

  .button[data-variant='main']:not([disabled]):hover,
  .button[data-variant='main'].loading {
    background: linear-gradient(131deg, theme('colors.action') 70%, transparent 72%) 100% 0/20px 100% no-repeat,
      linear-gradient(-49deg, theme('colors.action') 70%, transparent 72%) 0 0/20px 100% no-repeat,
      linear-gradient(theme('colors.action'), theme('colors.action')) 18px 0 / calc(100% - 36px) 100% no-repeat;
  }

  .button[data-variant='main-right'] {
    background: linear-gradient(131deg, theme('colors.icon') 70%, transparent 72%) 100% 0/20px 100% no-repeat,
      linear-gradient(-49deg, theme('colors.icon') 70%, theme('colors.icon') 72%) 0 0/20px 100% no-repeat,
      linear-gradient(theme('colors.icon'), theme('colors.icon')) 18px 0 / calc(100% - 36px) 100% no-repeat;
  }

  .button[data-variant='main-right']:not([disabled]):hover,
  .button[data-variant='main-right'].loading {
    background: linear-gradient(131deg, theme('colors.action') 70%, transparent 72%) 100% 0/20px 100% no-repeat,
      linear-gradient(-49deg, theme('colors.action') 70%, theme('colors.action') 72%) 0 0/20px 100% no-repeat,
      linear-gradient(theme('colors.action'), theme('colors.action')) 18px 0 / calc(100% - 36px) 100% no-repeat;
  }

  .button[data-variant='secondary'] {
    background: linear-gradient(131deg, #eee 70%, transparent 72%) 100% 0/20px 100% no-repeat,
      linear-gradient(-49deg, #eee 70%, transparent 72%) 0 0/20px 100% no-repeat,
      linear-gradient(#eee, #eee) 18px 0 / calc(100% - 36px) 100% no-repeat;
  }

  .button[data-variant='secondary']:not([disabled]):hover {
    background: linear-gradient(131deg, #ddd 70%, transparent 72%) 100% 0/20px 100% no-repeat,
      linear-gradient(-49deg, #ddd 70%, transparent 72%) 0 0/20px 100% no-repeat,
      linear-gradient(#ddd, #ddd) 18px 0 / calc(100% - 36px) 100% no-repeat;
  }

  .discount-badge {
    position: relative;
    display: block;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    font-weight: bold;
  }

  .flyout-animation {
    animation: slideIn 0.5s ease-out forwards;
    opacity: 0;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
